import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import ImageF from './ImageF'

class Features extends Component {
    constructor(props) { 
        super(props)
        this.items = props.items
        this.inverted = props.inverted
    }

    render() {
        const items = this.items.map((item, key) => {
            return(
                <Col lg="1" md="3" sm="4" key={key}>
                    <ImageF inverted={this.inverted} imgSrc={item.feature_image} text={item.feature_header} />
                </Col>
            )
        })

        return (
            <Row className="features">
                {items}
            </Row>
        )
    }
}

export default Features
