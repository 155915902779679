import React, { Component } from 'react'

class ImageF extends Component {
    constructor(props) {
        super(props)
        this.imgSrc = props.imgSrc
        this.text = props.text
        this.inverted = props.inverted
    }

    render() {
        return (
            <div className={this.inverted?"alt":""}>
                <img src={this.imgSrc} alt={this.text}/>
                <h5>{this.text}</h5>
            </div>
        )
    }
}

export default ImageF